<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box" style="max-width: 350px; margin: auto;">
        <div class="field">
          <div class="control">
            <p>{{ apiResult ? apiResult : 'Seguro que quiere finalizar el producto' }}</p>
          </div>
        </div>
        <div class="field is-grouped is-grouped-right">
          <div class="control">
            <button class="button is-danger is-outlined is-rounded is-medium" @click="close">Cerrar</button>
          </div>
          <div class="control">
            <button class="button is-danger is-rounded is-medium" :class="{ 'is-loading': loadingStatus }" @click="submit">Aceptar</button>
          </div>
        </div>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="close"></button>
  </div>
</template>

<script>
export default {
  name: 'ModalDelete',
  props: {
    close: {
      type: Function,
      default: () => {}
    },
    action: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      apiResult: null,
      error: null,
      showResult: false,
      loadingStatus: false
    }
  },
  methods: {
    async submit () {
      try {
        this.loadingStatus = true

        const response = await this.action(this.productCode)

        this.apiResult = response

        this.error = null
        this.showResult = true

        this.close()
      } catch (err) {
        this.error = 'Error al realizar la operación. Por favor, inténtalo de nuevo.'
        this.apiResult = null
      } finally {
        this.loadingStatus = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
.field {
  margin-bottom: 1rem;
}
.button.is-fullwidth {
  width: 100%;
}
.modal-background {
  background-color: rgba(0, 0, 0, 0.5);
}
.is-danger{
  background-color: #EB5965;
}
</style>
